import React, { useState, useEffect, useContext } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaHourglassStart, FaHourglassEnd } from "react-icons/fa";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Await, Link } from "react-router-dom";
import withRouter from "../../Common/withRouter";
import { createSelector } from "reselect";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

import { useSelector } from "react-redux";
import { json } from "node:stream/consumers";
import { log } from "node:console";
import MyContext from "MyContext/MyContext";
import axios from "axios";

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { shift, setShift, acceptChat } = useContext(MyContext);
  const UserData = JSON.parse(localStorage.getItem("authUser"));
  const [user, setUser] = useState<any>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  // useEffect(()=>{
  //   const userString = localStorage.getItem("authUser");
  //   if (userString) {
  //     const user = JSON.parse(userString);
  //     console.log(user, "Full user object");
  //     console.log(user.user.name.firstName, "First name");
  //     console.log(user.user.name.lastName, "Last name");

  //     const firstName = user.user.name.firstName;
  //     const lastName = user.user.name.lastName;
  //     setusername(`${firstName} ${lastName}`);
  //   }
  // },[])

  const getShiftDetails = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/user/check/shift/${UserData.user._id}`
    );
    console.log("res shift", res);
    if (res.success) {
      console.log("res shift ===>", res.data);
      setShift((prev) => res.data);
    }
  };

  const startSHift = async () => {
    setIsDisabled(true);
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/user/start/shift/${UserData.user._id}`
    );
    if (res.success) {
      console.log("res shift ===>", res.data);
      setShift((prev) => res.data);
      setTimeout(() => {
        setIsDisabled(false);
      }, 60000);
    } else {
      setIsDisabled(false);
    }
  };

  const endSHift = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/user/end/shift/${shift._id}`
    );
    if (res.success) {
      console.log("res shift ===>", res.data);
      setShift((prev) => ({}));
    }
  };

  console.log("res shift ===> state", shift);

  useEffect(() => {
    getShiftDetails();
  }, []);

  const profile = (state: any) => state.Profile;
  const selectProfileProperties = createSelector(profile, (success) => success);

  const { success } = useSelector(selectProfileProperties);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      {
        const obj = JSON.parse(localStorage.getItem("authUser") || "");
        setUser(obj);
        console.log(obj, "obj");
      }
    }
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user?.user?.profileImage ? user?.user?.profileImage : user1}
            alt="Header Avatar"
          />
          <span
            className="d-none d-xl-inline-block ms-2 me-1"
            style={{ color: "#232323" }}
          >
            {user?.user?.name?.firstName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href={process.env.PUBLIC_URL + "/profile"}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href={process.env.PUBLIC_URL + "/crypto-wallet"}>
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href={process.env.PUBLIC_URL + "/auth-lock-screen"}>
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" /> */}

          {(!shift?.status || shift?.status === "inactive") && (
            <DropdownItem
              className="dropdown-item"
              onClick={startSHift}
              disabled={isDisabled}
            >
              <FaHourglassStart className="mx-1 text-success font-size-16 align-middle me-1" />
              {/* <i className="bx bx-message-rounded-add font-size-16 align-middle me-1 text-danger" /> */}
              <span>{props.t("Start Shift")}</span>
            </DropdownItem>
          )}

          {shift?.status && shift?.status === "active" && !acceptChat && (
            <DropdownItem onClick={endSHift} className="dropdown-item">
              <FaHourglassEnd className="mx-1 text-danger font-size-16 align-middle me-1" />
              <span>{props.t("End Shift")}</span>
            </DropdownItem>
          )}

          <Link to="/logout" className="dropdown-item">
            <i className="mx-1 bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));
