import React, { useEffect, useContext, useState } from "react";
import "./App.css";
import { authProtectedRoutes, publicRoutes } from "./Routes/allRoutes";
import { Route, Routes } from "react-router-dom";
import VerticalLayout from "./Layouts/VerticalLayout";
import HorizotanlLayout from "./Layouts/HorizontalLayout/index";
import "./assets/scss/theme.scss";
import NonAuthLayout from "./Layouts/NonLayout";
import "../src/assets/scss/custom/pages/_quickReplies.scss";
import "../src/assets/scss/custom/components/liveChat.css";
import { TbDevicesX } from "react-icons/tb";
import axios from "axios";
//constants
import { LAYOUT_TYPES } from "./Components/constants/layout";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import AuthProtected from "Routes/AuthProtected";
import withRouter from "Components/Common/withRouter";

import io from "socket.io-client";

import MyContext from "MyContext/MyContext";

// Activating fake backend
fakeBackend();

const getLayout = (layoutType: any) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case LAYOUT_TYPES.VERTICAL:
      Layout = VerticalLayout;
      break;
    case LAYOUT_TYPES.HORIZONTAL:
      Layout = HorizotanlLayout;
      break;
    default:
      break;
  }
  return Layout;
};

function App(props: any) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 992);
  const User = JSON.parse(localStorage.getItem("authUser"));
  const {
    socket,
    setSocket,
    conversations,
    setConversations,
    setActiveCustomers,
    setQueue,
    notifySound,
    setNotifySound,
    setAcceptChat,
    setPeoplesQueue,
    setAccessChat,
    setCurrentConvo,
  } = useContext(MyContext);
  const UserData = JSON.parse(localStorage.getItem("authUser"));
  // const [sessionID, setSessionId] = useState(() => localStorage.getItem("session_id"));
  const sessionID = localStorage.getItem("session_id");

  const audio = new Audio("/assets/sound/message_sound.mp3");
  const [isOnline, setIsOnline] = useState<any>(navigator.onLine);
  const [connected, setConnected] = useState<boolean>(false);
  const [support, setSupport] = useState<any>(null);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 992);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const playNotificationSound = (notificationSound) => {
    const s = notificationSound;
    // JSON.parse(notificationSound)
    // console.log(s, "sound is here");

    // if (s) {
    //   console.log("playing sound", s);

    audio.play();
    // }
  };

  // const removeConversation = () => {
  //   var tempo_conversations = conversations;
  //   console.log(tempo_conversations, "tempo_conversations")
  //   const indexOfObject = tempo_conversations.findIndex(object => {
  //     return object?._id === removeId;
  //   });

  //   tempo_conversations.splice(indexOfObject, 1);
  //   console.log(tempo_conversations, "tempo_conversations after remonving")
  //   setConversations(tempo_conversations)
  //   if (conversations) {
  //     setCurrentConvo(conversations[0])
  //   }
  //   else {
  //     setCurrentConvo(null)
  //   }
  //   setRemoveId("")
  //   console.log("conversations after removing ", conversations)

  // }

  useEffect(() => {
    const goOnline = () => setIsOnline(true);
    const goOffline = () => setIsOnline(false);

    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    // Cleanup
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  // const getSupportField = async () => {
  //   if (!localStorage.getItem("sound")) {
  //     try {
  //       const headers = {
  //         Authentication: `Bearer ${UserData?.token}`,
  //       };
  //       const supportRes = await axios.get(`${process.env.REACT_APP_APIKEY}/api/get/support/${UserData?.user?._id}`, { headers })
  //       localStorage.setItem("sound", supportRes.data.sound)
  //       console.log("Unable To get Support Id", supportRes.data.sound)

  //       setNotifySound( (prev)=> (supportRes.data.sound))
  //     } catch (error) {
  //       console.log("Unable To get Support Id", error)
  //     }

  //   }
  //   else {
  //     console.log("sound get Item in else ", JSON.parse(localStorage.getItem("sound")))
  //   }
  // }

  console.log(sessionID, "sessionId");

  const startConnection = () => {
    try {
      const newSocket = io(
        "https://primexbroker.online",
        // "http://localhost:4001",
        // "http://16.170.27.13:4001",
        {
          path: "/api/socket.io",
          withCredentials: true,
          transports: ["websocket"],
          query: { sessionID: sessionID, userId: User?.user?._id },
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          reconnectionAttempts: Infinity,
        }
      );

      // mm
      newSocket.on("connect", () => {
        console.log("Socket connected", newSocket.id);

        if (sessionID) {
          const data = {
            customer: false,
            sessionID: localStorage.getItem("session_id"),
            userId: User.user._id,
          };
          newSocket.emit("update_socket", data);
          console.log("<<<<<<<<<<update socket if");
        } else {
          const data = {
            customer: false,
            userId: User.user._id,
          };
          newSocket.emit("update_socket", data);
          console.log("<<<<<<<<<<update socket else");
        }
      });

      if (User.token) {
        setConnected(true);
      }

      setSocket((prev) => {
        return newSocket;
      });

      if (newSocket) {
        newSocket.emit("is_accepting_chats", { userId: UserData.user._id });

        newSocket.on("total_queue_result", (data) => {
          setQueue((prev) => {
            return data.count;
          });
        });

        newSocket.on("active_customers_result", (data) => {
          console.log(data, "active_customers_result");

          setActiveCustomers((prev) => {
            return data.count;
          });
        });

        newSocket.on("sessionID", (newSessionID) => {
          console.log("Received newSessionID:", newSessionID);
          // setSessionId((old)=>{
          //   return newSessionID
          // }); // Update state

          // if (!localStorage.getItem("session_id")) {
          localStorage.setItem("session_id", newSessionID);
          // } else {
          //   const data = {
          //     customer: false,
          //     sessionID: localStorage.getItem("session_id"),
          //     userId: User.user._id,
          //   };
          //   newSocket.emit("update_socket", data);
          //   console.log("update socket after session");
          // } // Update localStorage
        });

        console.log(sessionID, "newSessionID state");

        newSocket.on("update_socket_result", (convers) => {
          console.log("new conversation", convers);
          console.log("old conversation", conversations);

          setConversations((old) => {
            return convers;
          });
        });
        newSocket.on("new_message", (newConversation: any) => {
          console.log("cono");

          setConversations((prevConversations) => {
            if (newConversation?.ended) {
              return newConversation?.activeChats;
              // return prevConversations; // Return the previous conversations as is
            } else if (newConversation.new) {
              return [...prevConversations, newConversation.conversation];
            } else {
              const index = prevConversations.findIndex(
                (conv) => conv?._id === newConversation?.conversation?._id
              );
              if (index !== -1) {
                const con = newConversation.conversation;
                if (
                  !newConversation?.conversation?.messages[
                    newConversation?.conversation?.messages?.length - 1
                  ]?.support
                ) {
                  setCurrentConvo((prev) => {
                    if (newConversation?.conversation?._id !== prev?._id) {
                      Object.assign(con, { unread: true });
                      Object.assign(con, { seen: false });
                    }
                    return prev;
                  });
                  setCurrentConvo((prev) => {
                    if (newConversation?.conversation?._id === prev?._id) {
                      console.log(
                        "id matched",
                        newConversation?.conversation?.messages[
                          newConversation?.conversation?.messages?.length - 1
                        ]?.support
                      );

                      newSocket.emit("msgSeenStatus", {
                        customer_socket_Id: prev.customer_socket_Id,
                        seen: true,
                      });
                    }
                    return prev;
                  });
                }

                console.log("cono", con);

                const updatedConversations = [...prevConversations];
                updatedConversations[index] = con;
                return updatedConversations;
              }
              return prevConversations;
            }
          });
          // console.log(newConversation.conversation.messages, "sound");

          if (
            !newConversation?.conversation?.messages[
              newConversation?.conversation?.messages?.length - 1
            ]?.support
          ) {
            setNotifySound((prev) => {
              playNotificationSound(prev);
            });
          }

          // }

          // console.log("after setting conversation in new_message Listerner", conversations)
        });

        newSocket.on("stop_accepting_chats_response", (data: any) => {
          console.log("stop result", data);
          if (data.success) {
            setAcceptChat(false);
          }
        });

        newSocket.on("stop_accepting_chats_response", (data: any) => {
          console.log("stop result", data);
          if (data.success) {
            setAcceptChat(false);
          }
        });

        newSocket.on("accepting_chats", (data: any) => {
          console.log("accepting_chats", data);
          if (data.accepting) {
            setAcceptChat(true);
          } else {
            setAcceptChat(false);
          }
        });

        newSocket.on("transfer_chats_response", (data: any) => {
          console.log("new data after transfer", data.conversation);
          if (data) {
            setConversations((prevConversations) => {
              return data.conversation;
            });
            // setSupport( data?.agents )
            // setConversations(data.conversation)
          }
        });

        newSocket.on("peoples_in_queue", (data: any) => {
          console.log("peoples in queue", data.customers);
          if (data) {
            setPeoplesQueue((prev) => {
              return data.customers;
            });
            // setSupport( data?.agents )
            // setConversations(data.conversation)
          }
        });

        newSocket.on("access_user_chat", (data: any) => {
          console.log("hello", data);
          console.log(data.message.body, "<<<<<data.message.body");
          setAccessChat((old) => data);
        });

        newSocket.on("force_disconnect_result", (data: any) => {
          if (data) {
            if (data.offline) setAcceptChat((old) => false);
          }
        });

        newSocket.on("ping", () => {
          console.log("Ping received");
          const data = {
            sessionID,
            userId: User?.user?._id,
            sock: newSocket.id,
          };
          newSocket.emit("pong", data); // Respond with pong
        });

        return () => {
          if (newSocket) {
            // newSocket.off("connect");
            newSocket.off("total_queue_result");
            newSocket.off("active_customers_result");
            newSocket.off("disconnect");
            newSocket.disconnect();
            newSocket.off("new_message");
            newSocket.off("sessionID");
            newSocket.off("update_socket_result");
            newSocket.off("stop_accepting_chats_response");
            newSocket.off("transfer_chats_response");
            newSocket.off("peoples_in_queue");
            newSocket.off("force_disconnect_result");
            newSocket.off("access_user_chat");
            newSocket.off("ping");
          }
        };
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(conversations, "new conversation >>>>>");

  useEffect(() => {
    if (User) {
      if (User.token && !connected) {
        startConnection();
        // getSupportField();
      }
    }

    // Cleanup function to disconnect the socket when component unmounts
  }, [isOnline, User]); // Empty dependency array to ensure this runs only once on mount

  // useEffect(()=>{
  //   if (!socket) {
  //     setConnected(false)
  //   }
  // },[connected, socket])

  const selectLeadData = createSelector(
    (state: any) => state.Layout,
    (layoutTypes) => layoutTypes
  );
  const { layoutTypes } = useSelector(selectLeadData);

  const Layout = getLayout(layoutTypes);

  useEffect(() => {
    if (User) {
      if (!User.token) {
        props.router.navigate("/login");
      }
    } else {
      props.router.navigate("/login");
    }
  }, [User]);
  // console.log(User, "user");
  

  // conditional rendering based on isDesktop state
  if ((!isDesktop && User?.user?.email !== "ahmed.ali@primexcapital.com" && User)  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "13px",
          height: "100vh",
          fontSize: "18px",
          color: "#232323",
        }}
      >
        <TbDevicesX style={{ fontSize: "100px", color: "#fccd0a" }} />
        <p>Not supported on mobile or tablet!</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <React.Fragment>
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>
              </React.Fragment>
            }
          />
        ))}
      </Routes>
    </React.Fragment>
  );
}

export default withRouter(App);
