import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  FormGroup,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { MdOutlinePassword } from "react-icons/md";
import TeamTable from "./TeamTable";
import { Email, Tags } from "./TeamCol";
import withRouter from "../../Components/Common/withRouter";
import avatar7 from "../../assets/images/users/avatar-7.jpg";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { RiDeleteBinLine, RiWifiOffLine } from "react-icons/ri";

import MyContext from "MyContext/MyContext";

// import {
//   getUsers as onGetUsers,
//   deleteUsers as onDeleteUsers,
//   addNewUser as onAddNewUser,
//   updateUser as onUpdateUser
// }
//   from "../../slices/contacts/thunk"
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "axios";

const ContactsList = () => {
  //meta title
  document.title = "User List | Skote - React Admin & Dashboard Template";
  const { socket, setSocket } = useContext(MyContext);

  // const selectProperties = createSelector(
  //   (state: any) => state.contacts,
  //   (users) => users,
  //   loading => loading
  // );

  const [users, setUsers] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [contact, setContact] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>(null);
  const [popover, setpopover] = useState<any>(null);
  const [fetch, setFetch] = useState<number>(0);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [passwordError, setPasswordError] = useState("");
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const user = JSON.parse(localStorage.getItem("authUser"));
  console.log(user, "useruser");

  const fetchAllSupport = async () => {
    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/get/all/support`
    );
    console.log(res, "response");
    if (res.success) {
      setUsers(res.data);
      setContact(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllSupport();
  }, [fetch]);

  const toggle = useCallback(() => {
    setModal((prev) => {
      if (prev === true) {
        setIsEdit(false);
        setEditData(false);
      }
      return !prev;
    });
  }, [modal]);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: isEdit ? editData?.name?.firstName : "",
      lastName: isEdit ? editData?.name?.lastName : "",
      role: isEdit
        ? editData?.role?.admin === true
          ? "Admin"
          : "Agent"
        : "Agent",
      email: isEdit ? editData?.email : "",
      password: "",
      designation: isEdit ? editData?.designation : "",
      chatLimit: isEdit ? editData?.chatLimit : 0,
      languages: isEdit ? editData.languages : [],
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      role: Yup.string().required("Please Select A role from Above"),
      email: Yup.string().email().required("Please Enter Email"),
      password: isEdit
        ? Yup.string()
        : Yup.string().required("Please Enter Password"),
      designation: Yup.string().required("Please Enter Designation"),
      chatLimit: Yup.number().required("Please Enter Chat Limit"),
      languages: Yup.array()
        .of(Yup.string())
        .required("Please select at least one language"),
    }),
    onSubmit: async (values: any) => {
      let data = {
        name: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
        email: values.email,
        password: values.password,
        designation: values.designation,
        role: values.role === "Admin" ? { admin: true } : { admin: false },
        account: "support",
        chatLimit: values.chatLimit,
        languages: values.languages,
      };

      if (image) {
        const file = image;
        let _id = "1";
        console.log(file);
        const formData = new FormData();
        formData.append("files[]", file);
        formData.append("action", "attachments");
        formData.append("_id", _id);

        const imgres = await axios.post(
          `${process.env.REACT_APP_APIKEY}/api/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (imgres) {
          setImage(null);
          console.log(imgres.result.file_path, "file path");
          Object.assign(data, { profileImage: imgres.result.file_path });
        }
      }

      if (!isEdit) {
        const res = await axios.post(
          `${process.env.REACT_APP_APIKEY}/api/admin/register`,
          data
        );
        if (res.success) {
          console.log(res.data, "res.data");
          setUsers((prev) => [...prev, res.data]);
          validation.resetForm();
          toggle();
        } else {
          console.log("failed response", res.data);
        }
      } else {
        delete data.password;
        const res = await axios.put(
          `${process.env.REACT_APP_APIKEY}/api/admin/edit/${editData._id}`,
          data
        );
        if (res.success) {
          const updatedUsers = users.map((user) => {
            if (user._id === editData._id) {
              return res.data; // Return new data for the matching id
            }
            return user; // Return the original user if not a match
          });
          setUsers(updatedUsers);
          setIsEdit(false);
          setEditData(null);
          validation.resetForm();
          toggle();
        } else {
          console.log("failed response", res.data);
        }
      }

      // const addInvite = {
      //   name: values.name,
      //   admin: (values.role === "Agent") ? false : true,
      //   email: values.email,
      // };
      // const headers = {
      //   Authentication: `Bearer ${user?.token}`,
      // };
      // console.log("submitted", addInvite)
      // validation.resetForm();
      // const res = await axios.post(`${process.env.REACT_APP_APIKEY}/api/create/add/send/invite`, addInvite, { headers })
      // console.log("Result After Invite sent ", res)
      // toggle();
    },
  });

  // const handleUserClick = useCallback((arg: any) => {
  //   const user = arg;

  //   setContact({
  //     id: user.id,
  //     name: user.name,
  //     designation: user.designation,
  //     email: user.email,
  //     tags: user.tags,
  //     projects: user.projects,
  //   });
  //   setIsEdit(true);

  //   toggle();
  // }, [toggle]);

  const handleEdit = (rowData) => {
    console.log(rowData, "rowData");

    setIsEdit(true);
    setEditData(rowData);
    toggle();
  };

  const handleOffline = (rowData) => {
    console.log(rowData, "rowData");

    if (socket) {
      console.log("socket", "rowData");
      socket.emit("force_disconnect", { userId: rowData._id });
      const newContacts = contact;
      const index = newContacts.findIndex((c) => c._id == rowData._id);
      setFetch((prev) => prev + 1);
    }
  };

  const showToast = (message, type = "success") => {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);

    setTimeout(() => {
      setToastVisible(false);
    }, 3000); // Hide the toast after 3 seconds
  };

  const toggleResetPasswordModal = () => {
    setResetPasswordModal(!resetPasswordModal);
  };

  const handleChangePasswordClick = (userId) => {
    setSelectedUserId(userId);
    toggleResetPasswordModal();
  };

  const handlePasswordChangeSubmit = async () => {
    if (newPassword !== confirmPassword) {
      showToast("Passwords do not match!", "danger");
      return;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/admin/change-password`,
        {
          userId: selectedUserId,
          newPassword,
        }
      );
      if (res.success) {
        showToast("Password changed successfully!", "success");
        setNewPassword("");
        setConfirmPassword("");
        setPasswordError("");
        toggleResetPasswordModal();
      } else {
        showToast("Failed to change password!", "danger");
      }
    } catch (error) {
      showToast("Error changing password!", "danger");
    }
  };

  const handleDeleteClick = (userId) => {
    setUserIdToDelete(userId);
    setDeleteUserModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_APIKEY}/api/admin/delete/${userIdToDelete}`
      );
      if (res.success) {
        setUsers(users.filter((user) => user._id !== userIdToDelete));
        setDeleteUserModal(false);
        showToast("User deleted successfully", "success");
      } else {
        showToast("Failed to delete user", "danger");
      }
    } catch (error) {
      showToast("Error deleting user", "danger");
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        disableFilters: true,
        filterable: true,
        Filter: false,
        accessor: (cellProps: any) => (
          <>
            {!cellProps.img ? (
              <div className="avatar-sm">
                <img
                  src={
                    cellProps?.profileImage ? cellProps.profileImage : avatar7
                  }
                  alt="{avatar7}"
                  className="avatar-title rounded-circle"
                />
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-sm"
                  src={
                    cellProps?.profileImage ? cellProps.profileImage : avatar7
                  }
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Name",
        filterable: true,
        Filter: false,
        accessor: (cellProps: any) => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  to="#"
                  className="text-dark"
                >{`${cellProps?.name?.firstName} ${cellProps?.name?.lastName}`}</Link>
              </h5>
              <p className="text-muted mb-0">{cellProps?.designation}</p>
            </>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Filter: false,
        Cell: (cellProps: any) => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Languages",
        accessor: "languages",
        filterable: true,
        Filter: false,
        Cell: (cellProps: any) => (
          <p className="text-muted mb-0">{cellProps.value.join(", ")}</p>
        ),
      },
      // {
      //   Header: "Rating",
      //   // accessor: "tags",
      //   filterable: true,
      //   Filter: false,
      //   accessor: (cellProps: any) => {
      //     return (
      //       <p className="text-muted mb-0">
      //         <i className="mdi mdi-star text-warning me-2"></i>
      //         {cellProps.rating.toFixed(1)}
      //       </p>
      //     );
      //   },
      // },
      {
        Header: "Likes",
        // accessor: "tags",
        filterable: true,
        Filter: false,
        accessor: (cellProps: any) => {
          console.log("cellProps.feedbackSum.likesSum", cellProps.totalLikes);
          return <p className="text-muted mb-0">{cellProps.totalLikes}</p>;
        },
      },
      {
        Header: "Dislikes",
        // accessor: "tags",
        filterable: true,
        Filter: false,
        accessor: (cellProps: any) => {
          return <p className="text-muted mb-0">{cellProps.totalDislikes}</p>;
        },
      },

      {
        Header: "Status",
        // accessor: "tags",
        filterable: true,
        Filter: false,
        accessor: (cellProps: any) => {
          return (
            <p className="text-muted mb-0">
              <i
                className={
                  cellProps.active
                    ? "mdi mdi-circle text-success align-middle me-2"
                    : "mdi mdi-circle align-middle me-1"
                }
              />
              {cellProps.active ? "Active now" : "Offline"}
            </p>
          );
        },
      },
      {
        Header: "Action",
        Filter: false,
        accessor: (cellProps: any) => {
          return (
            <div style={{ display: "flex" }}>
              <i
                style={{ fontSize: "16px" }}
                className="fas fa-edit icon-hover-edit mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  handleEdit(cellProps);
                }}
              ></i>
              {user.user._id !== cellProps._id && (
                <RiWifiOffLine
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                  className="icon-hover-offline mx-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOffline(cellProps);
                  }}
                />
              )}
              {user?.user?.role?.admin && user._id !== cellProps._id && (
                <>
                  <i
                    style={{ fontSize: "16px" }}
                    className="fas fa-key icon-hover-password mx-2"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangePasswordClick(cellProps._id);
                    }}
                  ></i>
                  <RiDeleteBinLine
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                    className="icon-hover-delete mx-2"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteClick(cellProps._id);
                    }}
                  />
                </>
              )}

              {/* <i style={{ fontSize: "16px" }} className="fas fa-trash-alt icon-hover-del" id="Popovertopdel" onClick={() => console.log()} ></i>
              <Popover
                placement="bottom"
                isOpen={popover}
                target={"Popovertopdel"}
                toggle={() => {
                  setpopover(!popover);
                }}
              >
                <PopoverHeader>Are you sure?</PopoverHeader>
                <PopoverBody>
                  <p >
                    This will delete the user and all the chats related to this user and It'll be permanent and connot be reverted.
                  </p>

                  <button onClick={() => {
                    setpopover(!popover);
                  }} className="btn btn-secondary" >cancel</button>
                  <button onClick={() => console.log("djhsdkjf")} className="btn btn-danger mx-2">Delete</button>
                </PopoverBody>
              </Popover> */}
            </div>
          );
        },
      },
    ],
    [handleEdit, handleChangePasswordClick, handleDeleteClick]
  );

  const teamFilter = ["Active", "In Active"]; //For Filter Table to check Active And In Active Team Members
  const [status, setCurrentStatus] = useState("");

  console.log(status, "<<<<status");

  useEffect(() => {
    if (status === "In Active") {
      console.log("In Active if");

      const filter = users.filter((support) => {
        if (support.active === false) {
          return support;
        }
      });
      setContact(filter);
    } else if (status === "Active") {
      const filter = users.filter((support) => {
        if (support.active === true) {
          return support;
        }
      });
      setContact(filter);
    } else if (status === "All") {
      setContact(users);
    }
  }, [status]);

  const changeStatus = (data) => {
    setCurrentStatus(data);
  };
  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  var node: any = useRef();
  const onPaginationPageChange = (page: any) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const handleOrderClicks = () => {
    setIsAdd(true);
    toggle();
  };

  const onCloseClick = () => {
    setShow(!show);
  };
  const submitData = async () => {};

  console.log(contact, "contact");

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => console.log()}
        onCloseClick={() => setDeleteModal(false)}
      />

      {/* edit modal */}

      {/* <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <div className="mb-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="avatar-xxl" >
                <img src={avatar7} className="avatar-title rounded-circle" alt="" />
              </div>
            </div>

            <div className="mb-3">
              <Input type="text"
                placeholder="First Name"
                onChange={(e) => console.log()
                }
                value={""} />
            </div>
            <div className="mb-3">
              <Input type="text"
                placeholder="Last Name"
                onChange={(e) => console.log()
                }
                value={""} />
            </div>

            <div className="mb-3">
              <Input type="email"
                placeholder="Email"
                onChange={(e) => console.log()
                }
                value={""} />
            </div>

            <div className="mb-3">
              <Input type="text"
                placeholder="Designation"
                onChange={(e) => console.log()
                }
                value={""} />
            </div>
          </ModalBody>
        </div>
      </Modal> */}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50vh",
                  }}
                >
                  {" "}
                  <Spinners />
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <TeamTable
                      columns={columns}
                      data={contact}
                      customPageSizeOption={true}
                      isGlobalFilter={true}
                      isPagination={false}
                      filterOption={true}
                      isShowingPageLength={true}
                      currentStatus={status}
                      setCurrentStatus={changeStatus}
                      customPageSize={contact?.length}
                      isAddOptions={true}
                      filterOptionData={teamFilter}
                      handleOrderClicks={handleOrderClicks}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      tableFor={"New Member"}
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              )}
              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">
                  {" "}
                  {isEdit ? " Edit Member " : "Add New Member"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <div className="mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div className="avatar-xl">
                              <img
                                src={
                                  isEdit
                                    ? editData.profileImage
                                      ? editData.profileImage
                                      : avatar7
                                    : avatar7
                                }
                                alt="{avatar7}"
                                className="avatar-title rounded-circle"
                              />
                            </div>
                            <Input
                              onChange={(e) => setImage(e.target.files[0])}
                              type="file"
                              color="success"
                              className="save-user mt-3 mb-2"
                              name={"Upload Picture"}
                            />
                          </div>

                          <Label>First Name</Label>
                          <Input
                            name="firstName"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                          validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label>Last Name</Label>
                          <Input
                            name="lastName"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                          validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label>Email</Label>
                          <Input
                            name="email"
                            label="Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.email}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label>Limit</Label>
                          <Input
                            name="chatLimit"
                            label="limit"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.chatLimit || ""}
                            invalid={
                              validation.touched.chatLimit &&
                              validation.errors.chatLimit
                                ? true
                                : false
                            }
                          />
                          {validation.touched.chatLimit &&
                          validation.errors.chatLimit ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.chatLimit}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                        {!isEdit && (
                          <div className="mb-3">
                            <Label>Password</Label>
                            <Input
                              name="password"
                              label="Password"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.password}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        )}

                        <div className="mb-3">
                          <Label>Designation</Label>
                          <Input
                            name="designation"
                            label="designation"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.designation || ""}
                            invalid={
                              validation.touched.designation &&
                              validation.errors.designation
                                ? true
                                : false
                            }
                          />
                          {validation.touched.designation &&
                          validation.errors.designation ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.designation}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label>Access Type</Label>
                          <Input
                            type="select"
                            name="role"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.role || ""}
                            invalid={
                              validation.touched.role && validation.errors.role
                                ? true
                                : false
                            }
                          >
                            <option>Admin</option>
                            <option>Agent</option>
                          </Input>
                          {validation.touched.role && validation.errors.role ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.role}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label>Languages</Label>
                          <div>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={validation.values.languages.includes(
                                    "English"
                                  )}
                                  onChange={() => {
                                    const nextLanguages =
                                      validation.values.languages.includes(
                                        "English"
                                      )
                                        ? validation.values.languages.filter(
                                            (lang) => lang !== "English"
                                          )
                                        : [
                                            ...validation.values.languages,
                                            "English",
                                          ];
                                    validation.setFieldValue(
                                      "languages",
                                      nextLanguages
                                    );
                                  }}
                                />{" "}
                                English
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={validation.values.languages.includes(
                                    "Arabic"
                                  )}
                                  onChange={() => {
                                    const nextLanguages =
                                      validation.values.languages.includes(
                                        "Arabic"
                                      )
                                        ? validation.values.languages.filter(
                                            (lang) => lang !== "Arabic"
                                          )
                                        : [
                                            ...validation.values.languages,
                                            "Arabic",
                                          ];
                                    validation.setFieldValue(
                                      "languages",
                                      nextLanguages
                                    );
                                  }}
                                />{" "}
                                Arabic
                              </Label>
                            </FormGroup>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <Button
                            type="submit"
                            color="success"
                            className="save-user"
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add"}{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
      <Toast
        isOpen={toastVisible}
        className={`bg-${toastType}`}
        style={{ position: "fixed", top: 90, right: 20, zIndex: 1000 }}
      >
        <ToastHeader icon={toastType}>Notification</ToastHeader>
        <ToastBody>{toastMessage}</ToastBody>
      </Toast>

      {/* Password reset modal */}
      <Modal isOpen={resetPasswordModal} toggle={toggleResetPasswordModal}>
        <ModalHeader toggle={toggleResetPasswordModal}>
          Reset Password
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label>New Password</label>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              invalid={passwordError.length > 0}
            />
          </div>
          <div className="mb-3">
            <label>Confirm Password</label>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              invalid={passwordError.length > 0}
            />
          </div>
          <div className="text-end">
            <Button color="primary" onClick={handlePasswordChangeSubmit}>
              Change Password
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <DeleteModal
        show={deleteUserModal}
        onDeleteClick={handleConfirmDelete}
        onCloseClick={() => setDeleteUserModal(false)}
      />
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
